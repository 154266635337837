const config = {
  apiKey: "AIzaSyAS9hIUBYVvJYABwVGsQKPeThyvACoIsc0", //FireStore
  //apiKey: "AIzaSyBtUKVRwMa2MhJA9hAYN5z_izkZGfY1mVo", //CloudIdentity
  //authDomain: "compass-saltsite.firebaseapp.com",
  authDomain: "auth.authcmp.wearewoodruff.xyz",
  databaseURL: "https://compass-saltsite.firebaseio.com",
  projectId: "compass-saltsite",
  storageBucket: "compass-saltsite.appspot.com",
  messagingSenderId: "584814696907",
  appId: "1:584814696907:web:1d42b7eecf94ee99117e4e",
  measurementId: "G-CNDJE8HXP4",
  confirmEmailRedirect: "https://saltsmartapp.comm"
};

class Firebase {
  constructor(app) {
    app.initializeApp(config);

    /* Helper */
    //this.serverValue = app.database.ServerValue; //Real-Time-DB
    this.fieldValue = app.firestore.FieldValue; //Cloud Firestore 
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */
    this.auth = app.auth();
    //this.db = app.database(); //Real-Time-DB
    //this.db = app.firestore(); //Cloud Firestore 
    this.db = app.firestore();//Cloud Firestore
    //app.firestore().enablePersistence().catch(err => console.log(err)); //Cloud Firestore Cache

    app.firestore().enablePersistence()
      .catch(function(err) {
        if (err.code == 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
        } else if (err.code == 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
        }
      });
     


    //app.auth().signInAnonymously().catch(err => console.log(err));

    /* Social Sign In Method Provider */
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();
    //this.samlProvider = new app.auth.SAMLAuthProvider('saml.compassminerals');
    this.samlProvider = new app.auth.SAMLAuthProvider('saml.compassokta');
    this.samlJumpProvider = new app.auth.SAMLAuthProvider('saml.jumpcloud-demo');   
  }

  

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () =>
    //this.auth.signInWithPopup(this.googleProvider);
    this.auth.signInWithRedirect(this.googleProvider);

  doSignInWithSaml = () =>
    //this.auth.signInWithPopup(this.samlProvider);
    this.auth.signInWithRedirect(this.samlProvider);

  doSignInWithSamlPop = () =>
    this.auth.signInWithPopup(this.samlProvider);
    //this.auth.signInWithRedirect(this.samlProvider);

  doSignInAnonymous = () =>
    this.auth.signInAnonymously().catch(err => console.log(err));

  doSignInWithJump = () =>
    //this.auth.signInWithPopup(this.samlJumpProvider);
    this.auth.signInWithRedirect(this.samlJumpProvider);

  doSignInWithFacebook = () =>
    this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () =>
    this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: "https://saltsmartapp.com",
    });

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        //var isAnonymous = authUser.isAnonymous;
        this.user(authUser.uid)
          //.once('value') //Real-Time-DB
          .get() //Cloud Firestore 
          .then(snapshot => {
            //const dbUser = snapshot.val(); //Real-Time-DB
            const dbUser = snapshot.data(); //Cloud Firestore 
            {/*
            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }
            */}

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              username: authUser.username,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(authUser);
          });
        } else {
          fallback();
          return
        }
  });

  // *** User API *** Real-Time-DB
  //user = uid => this.db.ref(`users/${uid}`);
  //users = () => this.db.ref('users');
  // *** User API *** Cloud Firestore 
  user = uid => this.db.doc(`users/${uid}`);
  users = () => this.db.collection('users');

  // *** Message API *** Real-Time-DB
  //message = uid => this.db.ref(`messages/${uid}`);
  //messages = () => this.db.ref('messages');
  // *** Message API *** Cloud Firestore 
  message = uid => this.db.doc(`messages/${uid}`);
  messages = () => this.db.collection('messages');

  // *** Portfolios (Collections) API ***  Real-Time-DB
  collection = uid => this.db.ref(`collections/${uid}`);
  collections = () => this.db.ref('collections');
  // *** Portfolios (Collections) API ***  Cloud Firestore  
  collection = uid => this.db.doc(`collections/${uid}`);
  collections = () => this.db.collection('collections');
}

let firebase;

function getFirebase(app, auth, database, firestore) {
  if (!firebase) {
    firebase = new Firebase(app, auth, database, firestore);
  }

  return firebase;
}

export default getFirebase;